import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import AuthManager from "../../utils/AuthManager";
import General from "../../utils/General";
import Event from "../../utils/Event";
import Backend from "../../utils/Backend";
import Notify from "../../utils/Notify";
import { PASSCODE_ICON_UPDATED, USER_UPDATED } from "../../constants/Events";
import boopSfx from '../../assets/sound/newOrder.mp3';
import Form from 'react-bootstrap/Form';

export default class Header extends Component {
  
  constructor(props) {
   
    
    super(props);
  
    

    this.state = {
      currentUser: AuthManager.currentUser.user,
      orders: null,
      count: 0,
      notifOn:0
    };
  }

  componentDidMount() {
    Event.on(USER_UPDATED, () =>
      this.setState({
        currentUser: AuthManager.currentUser.user,
      })
    );

    Event.on(PASSCODE_ICON_UPDATED, (value) => {
      this.setState({
        passcode: value,
      });
    });

    

    this.refreshOrders = setInterval(() => {

      this._getOrders();
      
    }, 60000);
  }

  componentWillUnmount() {
    Event.off(USER_UPDATED);

    Event.off(PASSCODE_ICON_UPDATED);

    clearInterval(this.refreshOrders);
  }


  // componentDidMount() {
  //   let msc = new Audio(boopSfx);


  //   setTimeout(function(){
  //      msc.play();
  //      alert("new Order For You");
  //   },6000);az webapp list-runtimes --os linux | grep PHP
   
  // }
  _getOrders() {
    this.setState({ isLoading: true });
    
    
    
    Backend.getOrders()
      
      .then((orders) => {
        
        const {count} = this.state;
        // count is initialised at zero in the class constructor
        //if ((orders.total_count>0) &&  orders.total_count > count){
        if (orders.total_count>0){
          //if the total number of orders in different to our counter we play the sound
          if (this.state.notifOn==1) {
            let curAud = document.getElementById("audioPlay");
            curAud.muted = false;
            curAud.play()
            let counterStart = orders.total_count + 1;
            this.setState({ count: this.state.count + counterStart })
          }
          // increment the counter to ensure that unless a new order comes in the sound will not be played
        }  
        
        
        
        this.setState({ orders, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }
 
  render() {
    
    const turnOnNotif = (event) => {
      if (event.target.checked) {
        this.setState({
          notifOn:1
        })
      } else {
        this.setState({
          notifOn:0
        })
      }
    }

    const { currentUser, orders, passcode } = this.state;
    
    return (

      <>
      <audio style={{display:"none"}} controls autoPlay muted id="audioPlay">
        <source src={boopSfx} type="audio/mpeg"/>
      </audio>
        <div id="kt_header" className="header flex-column header-fixed">
          <div className="header-top">
            <div className="container">
              <div className="d-none d-lg-flex align-items-center mr-3">
                <a href="/" className="mr-20">
                  <img
                    src="/assets/media/logos/clinks-white.svg"
                    style={{ width: 100 }}
                    alt="Logo"
                  />
                </a>

                <ul className="header-tabs nav align-self-end font-size-lg">
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link py-4 px-6 active font-weight-boldest"
                          : "nav-link py-4 px-6 font-weight-boldest"
                      }
                    >
                      Outstanding Orders
                      {orders?.total_count > 0 && (
                        <span className="label label-sm label-primary ml-2">
                          {orders?.total_count}
                        </span>
                      )}
                    </NavLink>
                  </li>

                  <li className="nav-item ml-2">
                    <NavLink
                      exact
                      to="/past-orders"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link py-4 px-6 active font-weight-boldest"
                          : "nav-link py-4 px-6 font-weight-boldest"
                      }
                    >
                      Past Orders
                    </NavLink>
                  </li>

                  <li
                    className="nav-item"
                  >
                    {AuthManager.currentUser.active_venue && (
                      <>
                        <NavLink
                          exact
                          to={`/menus/${AuthManager.currentUser.active_venue}`}
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link py-4 px-6 active font-weight-boldest"
                              : "nav-link py-4 px-6 font-weight-boldest"
                          }
                        >
                          Manage Menu
                          {!passcode && (
                            <span className="svg-icon svg-icon-primary svg-icon-md ml-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <mask fill="white">
                                    <use xlinkHref="#path-1" />
                                  </mask>
                                  <g />
                                  <path
                                    d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"
                                    fill="#000000"
                                  />
                                </g>
                              </svg>
                            </span>
                          )}
                        </NavLink>
                      </>
                    )}
                  </li>

                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/manage-stores"    
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link py-4 px-6 active font-weight-boldest"
                          : "nav-link py-4 px-6 font-weight-boldest"
                      }
                    >
                      Manage Stores
                    </NavLink>
                   
                  </li>
                  <li className="nav-item">
                      <Form.Check style={{position: "relative",top: "14px"}}
                        type="switch"
                         id="custom-switch"
                          label="Notifications"
                        onChange={turnOnNotif}
                      />
                  </li>
                </ul>
              </div>

              <div className="topbar">
                <div className="topbar-item">
                  <div
                    className="btn btn-icon btn-hover-transparent-white w-auto d-flex align-items-center btn-lg px-2"
                    id="kt_quick_user_toggle"
                  >
                    <div className="d-flex flex-column text-right pr-3">
                      <span className="text-white opacity-50 font-weight-bold font-size-sm d-none d-md-inline">
                        {currentUser?.first_name}
                      </span>
                      <span className="text-white font-weight-bolder font-size-sm d-none d-md-inline">
                        {currentUser?.last_name}{" "}
                      </span>
                    </div>
                    {currentUser.company?.logo?.original ? (
                      <span className="symbol symbol-35">
                        <img
                          src={currentUser.company?.logo?.original}
                          alt="Logo"
                        />
                      </span>
                    ) : (
                      <div className="symbol symbol-35">
                        <div
                          className="symbol-label"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <h1 className="m-0" style={{ fontSize: "16px" }}>
                            {General.getInitials(
                              `${currentUser?.first_name} ${currentUser?.last_name}`
                            )}
                          </h1>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
    
  }
  
}
// export {orders}
